import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Row from 'react-bootstrap/Row';
import {
  Typography,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  InputAdornment,
  Autocomplete
} from "@mui/material";
import "./landing.css";
import BAN from "../../assets/img/BAN-1.jpg"
import BAN2 from "../../assets/img/BAN-2.jpg"
import BAN3 from "../../assets/img/BAN-3.jpg"
import BAN4 from "../../assets/img/BAN-4.jpg"
import BAN5 from "../../assets/img/BAN-5.jpg"
import BAN6 from "../../assets/img/BAN-6.jpg"
import LOGO from "../../assets/img/bm-img/bm-logo.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Basic styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars} from "@fortawesome/free-solid-svg-icons";

import Select from 'react-select';

import LandingHeader from '../../components/Landing/Header/landingheader';
import LandinFooter from '../../components/Landing/Footer/landingfooter';
const drawerWidth = 240;
const navItems = ['Login', 'LogOut'];

const Landing = (props) => {
  const [modalListShow, setModalListShow] = useState(false);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const options = [
    { value: 'ABC', label: 'ABC' },
    { value: 'DEF', label: 'DEF' },
    { value: 'GHI', label: 'GHI' }
  ]

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} className="nav-main-menu"/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const ImageCarousel = () => {
    return (
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {/* Replace with your image URLs */}
        <SwiperSlide><img src={BAN} alt="Slide 1" /></SwiperSlide>
        <SwiperSlide><img src={BAN2} alt="Slide 2" /></SwiperSlide>
        <SwiperSlide><img src={BAN3} alt="Slide 3" /></SwiperSlide>
        <SwiperSlide><img src={BAN4} alt="Slide 1" /></SwiperSlide>
        <SwiperSlide><img src={BAN5} alt="Slide 2" /></SwiperSlide>
        <SwiperSlide><img src={BAN6} alt="Slide 3" /></SwiperSlide>
      </Swiper>
    );
  };
 
  return (
   
    <Container fluid>
    <Row>
      <ImageCarousel />
      {/* <Container maxWidth="xl"> */}
      
    
    {/* <Box  sx={{ display: 'flex','& .MuiAppBar-root': {
          background: '#fff',
          boxShadow: 'none'
    }, }}>
      <CssBaseline />
      <AppBar component="nav"  >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <Link to="/landing" className="me-2"><img src={LOGO} alt="" width={"150px"}/></Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      </Box> */}
     
     <LandingHeader></LandingHeader>
     
    
      <section className="">
        <div className="str">
          <div className="hom-head">
            <div className="container">
              <div className="row">
                <div className="hom-ban ">
                  <div className="ban-tit">
                    <span>
                      <i className="no1"></i>Banagar's Matrimony
                    </span>
                    <h1>
                      Find your
                      <br />
                      <b>Right Match</b> here
                    </h1>
                    <p>Only for Banagar's Community.</p>
                  </div>
                  <Box className="ban-search chosenini d-none">
                    <ul>
                      <li>
                        <label  id="aria-label" htmlFor="aria-example-input" className="text-white">
                        I'm looking for a
                        </label>
                          <Select
                            aria-labelledby="aria-label"
                            inputId="aria-example-input"
                            className="basic-single"
                            classNamePrefix="select"
                            
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            options={options}
                        />
                      </li>
                      <li>
                        <label  id="aria-label" htmlFor="aria-example-input" className="text-white">
                        Age
                        </label>
                          <Select
                            aria-labelledby="aria-label"
                            inputId="aria-example-input"
                            className="basic-single"
                            classNamePrefix="select"
                            
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            options={options}
                        />
                      </li>
                      <li>
                        <label  id="aria-label" htmlFor="aria-example-input" className="text-white">
                        Religion
                        </label>
                          <Select
                            aria-labelledby="aria-label"
                            inputId="aria-example-input"
                            className="basic-single"
                            classNamePrefix="select"
                            
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            options={options}
                        />
                      </li>
                      <li>
                        <label  id="aria-label" htmlFor="aria-example-input" className="text-white">
                        Location
                        </label>
                          <Select
                            aria-labelledby="aria-label"
                            inputId="aria-example-input"
                            className="basic-single"
                            classNamePrefix="select"
                          
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            options={options}
                        />
                      </li>
                      <li>
                      <Button variant="contained">Search</Button>
                      </li>
                    </ul>    
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
  <div className="hom-ban-sli " >
    <div>
           
      <div className="image d-none">
        <img src={BAN} alt="" />
      </div>
           
    </div>
  </div>
</section>
             

      
          
          </Row>
<LandinFooter></LandinFooter>
</Container>
    
    
    
  );
}



export default Landing;