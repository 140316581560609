import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {
  Typography,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  InputAdornment,
  Autocomplete
} from "@mui/material";

import BAN from "../../assets/img/BAN-2.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Basic styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars} from "@fortawesome/free-solid-svg-icons";
import LOGO from "../../assets/img/bm-img/bm-logo.jpg"
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import LandingHeader from '../../components/Landing/Header/landingheader';
import LandinFooter from '../../components/Landing/Footer/landingfooter';
const drawerWidth = 240;
const navItems = ['Login', 'Contact'];

const TermUse = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const options = [
    { value: 'ABC', label: 'ABC' },
    { value: 'DEF', label: 'DEF' },
    { value: 'GHI', label: 'GHI' }
  ]

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  
  
  return (
    <>
     
     <Container fluid style={{marginBottom:"50px;"}}>
      <Row>
      <LandingHeader></LandingHeader>
      <div className="row" style={{"margin-top":"125px"}}>
        <div className="col-md-12">
            <h6 className="fw-bold pb-3"><span class="">Terms of Use for Banagars Matrimony</span></h6>
            <div className="mb-4">
                <p class="fw-semibold mb-2 fs-14">1 Acceptance of Terms</p>
                <p class="mb-0 text-muted">
                By creating an account or using any services provided by Banagars Matrimony, you agree to comply with and be bound by these Terms of Use and our Privacy Policy. These terms may be updated or modified at any time, with or without prior notice.
                </p>
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">2 Eligibility</p>
                <ul>
                  <li className="mb-0 text-muted">
                 
                You must be at least 18 years old (for females) and 21 years old (for males) to register or use the services provided by Banagars Matrimony, in accordance with Indian law.
              
                  </li>
                  <li className="mb-0 text-muted">
                 You represent and warrant that all registration information you submit is truthful, accurate, and you agree to maintain the accuracy of such information
                  </li>
                  <li className="mb-0 text-muted">
                  You acknowledge that Banagars Matrimony is intended solely for individuals seeking matrimonial relationships. You may not use the service for casual dating or any non-matrimonial purposes.
                 
                  </li>
                </ul>
                
                
               
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">3.Account Responsibility</p>
                <p className="mb-0 text-muted">
                You are responsible for maintaining the confidentiality of your login credentials and are fully responsible for all activities that occur under your account.
                </p>
                <p className="mb-0 text-muted">
                Banagars Matrimony is not responsible for any unauthorized access to your account. Please notify us immediately if you suspect any breach of security.
                </p>
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">4.User Conduct</p>
                When using Banagars Matrimony, you agree not to:
                <ul className="mb-0 text-muted">
                    
                    <li className="mb-0 text-muted">Use the service for any illegal or unauthorized purpose.</li>
                    <li className="mb-0 text-muted">Post or transmit any content that is false, misleading, defamatory, obscene, offensive, or promotes hate.</li>
                    <li className="mb-0 text-muted">Harass, abuse, or harm another user in any way.</li>
                    <li className="mb-0 text-muted">Impersonate any person or entity or misrepresent yourself.</li>
                    <li className="mb-0 text-muted">Use automated scripts to interact with the platform.</li>
                    <li className="mb-0 text-muted">We reserve the right to terminate your account without notice if any of these rules are violated.</li>
                </ul>
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">5. Content Ownership and License</p>
                <p className="mb-0 text-muted">
                You retain ownership of the content you post on Banagars Matrimony. However, by posting content, you grant Banagars Matrimony a non-exclusive, worldwide, royalty-free license to use, modify, distribute, and display your content on the platform.
                </p>
                <p className="mb-0 text-muted">
                Banagars Matrimony does not endorse or verify the accuracy of any content posted by users.
                </p>
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">6. Subscription and Payment</p>
                <p className="mb-0 text-muted">
                Some services offered by Banagars Matrimony may require a subscription or payment. All fees are non-refundable, and the prices are subject to change without notice.

                </p>
                <p className="mb-0 text-muted">
                You agree to provide accurate payment information and comply with all applicable tax laws.
                </p>
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">7. Termination</p>
                <p className="mb-0 text-muted">
                Banagars Matrimony reserves the right to terminate or suspend your account at any time, with or without cause, and with or without prior notice. Upon termination, your right to use the service will cease immediately.

                </p>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">8. Disclaimers and Limitation of Liability</p>
                <p className="mb-0 text-muted">
                Banagars Matrimony provides the service on an "as is" and "as available" basis without any warranties, either express or implied.
                </p>
                <p className="mb-0 text-muted">
                We do not guarantee the accuracy, completeness, or usefulness of any content, and you use Banagars Matrimony at your own risk.
                </p>
                <p className="mb-0 text-muted">Banagars Matrimony shall not be liable for any damages, including but not limited to, loss of data, emotional distress, or any other damages resulting from the use or inability to use the platform.</p>
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">9. Governing Law</p>
                <p className="mb-0 text-muted">
                These Terms of Use shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under or in connection with these terms shall be resolved exclusively in the courts of [Your Jurisdiction].
                </p>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">10. Changes to Terms</p>
                <p className="mb-0 text-muted">
                Banagars Matrimony reserves the right to modify these Terms of Use at any time. Your continued use of the platform after such changes constitutes your acceptance of the revised terms.

                </p>
                
            </div>
            <div className="mb-4">
                <p className="fw-semibold mb-2 fs-14">
                11. Contact Information</p>
                <p className="mb-0 text-muted">
                If you have any questions or concerns regarding these Terms of Use, please contact us at:

                </p>
                <p className="mb-0 text-muted">Email: banagarsmatrimony@gmail.com</p>
                <p className="mb-0 text-muted">Phone: +918904875526</p>
                
            </div>
            <div className="mb-4">
                
                <p className="mb-0 text-muted">
                By using Banagars Matrimony, you agree to these terms and any future updates or amendments.

                </p>
                <p className="mb-0 text-muted">This version of the Terms of Use was last updated on 10-12-2024.</p>
                
                
            </div>
            

        </div>
            
      </div>  
      </Row>
         
    
      </Container>
      <LandinFooter></LandinFooter>
    </>
    
    
  );
}



export default TermUse;