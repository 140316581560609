import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "react-bootstrap";

import {
  Button,
  Typography,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  InputLabel ,
  InputAdornment,
  Autocomplete
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { format } from "date-fns";
import { useParams, useLocation } from "react-router-dom";
import UserContext from "../../../context/userProfile-list/UserProvider";

const FamilyDetailForm = ({ userID,personalData,professionsList,fatherProfId,motherProfId,submitFrom
 }) => {
  const { updateFamilyDetail} = useContext(UserContext);
  const [fatherProfID, setFatherProfID] = useState(fatherProfId);
  const [motherProfID, setMotherProfID] = useState(motherProfId);
  const location = useLocation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      fatherName: personalData?.fatherName ? personalData?.fatherName : "",
      matherName: personalData?.motherName ? personalData?.motherName : "",
      fatherProf:fatherProfId[0]?.id,
      motherProf:motherProfId[0]?.id,
      numSis:personalData?.numberOfSisters ? personalData?.numberOfSisters : "",
      numBro: personalData?.numberOfBrothers ? personalData?.numberOfBrothers : "",
      homePhNo:personalData?.homePhone ? personalData?.homePhone : "",
      
    },
  });
  useEffect(() => {

    return () => {
      reset();
    };
  }, [location, reset]);
  const onSubmit = (data) => {
    let inputData={
      "fatherName": data.fatherName,
      "motherName": data.matherName,
      "fatherOccupation": data.fatherProf,
      "motherOccupation": data.motherProf,
      "numberOfSisters": parseInt(data.numSis),
      "numberOfBrothers": parseInt(data.numBro),
      "homePhoneNumber": data.homePhNo
    }
    submitFrom("Family",personalData?.userId,inputData)
  };
  return (
    <>
   
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row gy-3">
            <div className="col-md-12">
              <Controller
                name="fatherName"
                control={control}
                rules={{ required: "Father Name is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Father Name"
                      {...field}
                      error={!!errors.fatherName}
                      helperText={errors.fatherName ? errors.fatherName.message : ""}
                    >
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-md-12">
              <Controller
                name="matherName"
                control={control}
                rules={{ required: "Mother Name is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Mother Name"
                      {...field}
                      error={!!errors.matherName}
                      helperText={errors.matherName ? errors.matherName.message : ""}
                    >
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-md-6">
              {/* <Controller
                name="fatherProf"
                control={control}
                rules={{ required: "Father Profession is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                      size="small"
                      label="Select Father profession"
                      {...field}
                      error={!!errors.fatherProf}
                      helperText={errors.fatherProf ? errors.fatherProf.message : ""}
                    >
                      {professionsList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.professionType}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              /> */}
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="select-father-profession">Select Father profession</InputLabel>
                <Controller
                  name="fatherProf"
                  control={control}
                  render={({ field }) => (
                    <Select labelId="select-father-profession" 
                    label="Select your profession" 
                    error={!!errors.fatherProf}
                  helperText={errors.fatherProf ? errors.fatherProf.message : ""}
                    {...field}>
                       {professionsList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.professionType}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl> */}
              <Autocomplete
               name="fatherProf"
                options={professionsList}
                getOptionLabel={(option) => option.professionType}
                onChange={(event, newValue) => {
                  setFatherProfID(newValue);
                }}
                value={fatherProfID}
                renderInput={(params) => (
                  <TextField {...params} label="Select Father profession" variant="outlined" />
                )}
              />
            </div>
            <div className="col-md-6">
              {/* <Controller
                name="motherProf"
                control={control}
                rules={{ required: "Mother profession is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                      size="small"
                      label="Select Mother profession"
                      {...field}
                      error={!!errors.motherProf}
                      helperText={errors.motherProf ? errors.motherProf.message : ""}
                    >
                        {professionsList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.professionType}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              /> */}
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="select-mother-profession">Select Mother profession</InputLabel>
                <Controller
                  name="motherProf"
                  control={control}
                  render={({ field }) => (
                    <Select labelId="select-mother-profession" 
                    label="Select Mother profession" 
                    error={!!errors.motherProf}
                  helperText={errors.motherProf ? errors.motherProf.message : ""}
                    {...field}>
                       {professionsList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.professionType}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl> */}
              <Autocomplete
               name="motherProf"
                options={professionsList}
                getOptionLabel={(option) => option.professionType}
                onChange={(event, newValue) => {
                  setMotherProfID(newValue);
                }}
                value={motherProfID}
                renderInput={(params) => (
                  <TextField {...params} label="Select Mother profession" variant="outlined" />
                )}
              />
            </div>
            <div className="col-md-12">
              <Controller
                name="numSis"
                control={control}
                rules={{ required: "Number of sister required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Number of Sister"
                      {...field}
                      error={!!errors.numSis}
                      helperText={errors.numSis ? errors.numSis.message : ""}
                    >
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-md-12">
              <Controller
                name="numBro"
                control={control}
                rules={{ required: "Number of brother is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Number of Brother"
                      {...field}
                      error={!!errors.numBro}
                      helperText={errors.numBro ? errors.numBro.message : ""}
                    >
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="text-end d-flex justify-content-end">
            <button className="btn btn-primary-light me-2">
              <i className="ri ri-refresh-line me-1" /> Update{" "}
            </button>
            <button className="btn btn-secondary-light">
              <i className="ri ri-close-circle-line me-1" />
              Cancel
            </button>
          </div>
        </div>
      </form>
      
   
    </>
  );
};

export default FamilyDetailForm;
