import React, { useContext, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import {
    Stepper, Step, StepLabel, Button, Typography, TextField, Box
  } from '@mui/material';
  const steps = ['Personal Information', 'Contact Details', 'Review'];
const StepperModal = () => {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [activeStep, setActiveStep] = useState(0);
  const { control, handleSubmit, trigger, formState: { errors } } = useForm();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleNext = async () => {
    const isValid = await trigger(getStepFields(activeStep));
    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = (data) => {
    setFormData(data);
    handleNext();
  };

  const getStepFields = (step) => {
    switch (step) {
      case 0:
        return ['name'];
      case 1:
        return ['email', 'phone'];
      default:
        return [];
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Controller
              name="name"
              control={control}
              defaultValue={formData.name}
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  margin="normal"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                />
              )}
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            <Controller
              name="email"
              control={control}
              defaultValue={formData.email}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  margin="normal"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue={formData.phone}
              rules={{
                required: 'Phone number is required',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Invalid phone number',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone"
                  margin="normal"
                  fullWidth
                  error={!!errors.phone}
                  helperText={errors.phone ? errors.phone.message : ''}
                />
              )}
            />
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6">Review your details</Typography>
            <Typography>Name: {formData.name}</Typography>
            <Typography>Email: {formData.email}</Typography>
            <Typography>Phone: {formData.phone}</Typography>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };
    return (
      <>
         <div className="main-content app-content ">
          <div className="container-fluid">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb"></div>
        <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button>
  
        <Modal show={show} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body><Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography variant="h6">All steps completed</Typography>
            <Button onClick={() => setActiveStep(0)}>Reset</Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {getStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button variant="contained" color="primary" type="submit">
                  Finish
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>
              )}
            </Box>
          </form>
        )}
      </div>
    </Box></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        </div>
        </div>
      </>
    );
  }
export default StepperModal;