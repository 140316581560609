const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "LOGIN_RESET":
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        auth: action.payload,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case "GET_LOGGED_USER_DETAILS":
      return {
        ...state,
        isLoading: true,
        isUserError: false,
      };
    case "GET_LOGGED_USER_DETAILS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isUserError: false,
        userDetails: action.payload,
      };
    case "GET_LOGGED_USER_DETAILS_FAILED":
      return {
        ...state,
        isLoading: false,
        isUserError: true,
      };
    case "GET_APPLICATION_DETAILS":
      return {
        ...state,
        applicationIds: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
