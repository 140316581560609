import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
const LandinFooter = () => {
  
    return(
       
        <div className="footer px-2 text-center">
            <div className="row align-items-center">
                <div className="col-md-12 ">
                    <p >Copyright © 2024 Banagars Matrimony. <Link to="/term-use" className="me-2">Terms of use</Link>| <Link to="/privacy-policy" className="ms-2"> Privacy Policy</Link></p>
                
                </div>
                
            </div>
      </div>
      
    )

}
export default LandinFooter;