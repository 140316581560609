import React, { useContext, useEffect, useMemo, useRef, useState } from "react";


import LOGO from "../../../assets/img/bm-img/bm-logo.jpg"

import 'swiper/css'; // Basic styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Link } from "react-router-dom";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars} from "@fortawesome/free-solid-svg-icons";


import LoginModal from '../../Modals/Login'

const LandingHeader = () => {
    const [modalListShow, setModalListShow] = useState(false);
    const handleOpenLogin =   (id) => {
        setModalListShow(true);
      };
    return(
        <section className="landing-header w-100 p-0">
             {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3 custom-main-nav w-100" >
         
            <Navbar.Brand ><Link to="/" ><img src={LOGO} alt="logo" /></Link></Navbar.Brand>
          
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} >
            <FontAwesomeIcon icon={faBars} />
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="custom-main-navmenu"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 ">
                  <Nav.Link href="#" onClick={() => handleOpenLogin()}>Login</Nav.Link>
                  {/* <Nav.Link href="#">Log Out</Nav.Link> */}
                 
                </Nav>
               
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            
        </Navbar>
      ))}
      <LoginModal  show={modalListShow} onHide={() => setModalListShow(false)}/>
        </section>
    )

}
export default LandingHeader;