import React, { useContext, useState } from "react";
import { useForm,Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "react-bootstrap";
import AuthContext from "../../context/user-auth/AuthProvider";
import {TextField } from "@mui/material";
import { DatePicker,LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const UserRegister = () => {

  const { UserRegisterAPI ,isLoading} = useContext(AuthContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({
    defaultValues: {
      
    },
  });
  
  const password = watch("password", "");

  const onSubmit = async (e) => {
    const response = await UserRegisterAPI(e);
   
  };
  return(
    <>
    
  <div className="container-lg">
    <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
      <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
        <div className="my-4 d-flex justify-content-center">
          {/* <a href="index-2.html">
            <img
              src="build/assets/img/brand-logos/desktop-logo.png"
              alt="logo"
              className="desktop-logo"
            />
            <img
              src="build/assets/img/brand-logos/desktop-dark.png"
              alt="logo"
              className="desktop-dark"
            />
          </a> */}
        </div>
        <div className="card custom-card">
          <div className="card-body">
            <div className="authentication-cover">
              <div className="aunthentication-cover-content">
                <p className="h5 fw-bold mb-2 text-center">Sign Up</p>
                {/* <div className="text-center">
                  <p className="fs-14 text-muted mt-3">
                    Already have an account?{" "}
                    <a href="signin.html" className="text-primary fw-semibold">
                      Sign In Here
                    </a>
                  </p>
                </div>
                <div className="d-grid align-items-center">
                  <button className="btn btn-outline-light border shadow-sm">
                    <img
                      src="build/assets/img/authentication/social/1.png"
                      className="w-4 h-4 me-2"
                      alt="google-img"
                    />
                    Sign in with Google
                  </button>
                </div>
                <div className="text-center my-3 authentication-barrier">
                  <span>OR</span>
                </div> */}
                                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row gy-3">

                  <div className="col-xl-12">
                    <label
                      for="signup-firstname"
                      className="form-label text-default op=8"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="signup-firstname"
                      placeholder="first name"
                      control={control} {...register("firstName", { required: "This field is required" })} aria-invalid={errors.firstName ? true : false} 
                    />
                    <ErrorMessage
                    errors={errors}
                    name="firstName"
                    render={({ message }) => (
                        <Alert variant="danger" className="p-1 mb-0">
                        {message}
                        </Alert>
                    )}
                    />
                   
                  </div>
                  <div className="col-xl-12">
                    <label
                      for="signup-lastname"
                      className="form-label text-default op=8"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="signup-lastname"
                      placeholder="last name"
                      control={control} {...register("lastName")} 
                      />
                      
                  </div>
                  <div className="col-xl-12">
                  <label
                      className="form-label text-default op=8 d-block"
                    >
                     Date of Birth
                    </label>
                  <Controller
                      name="birthDate"
                      control={control}
                      rules={{ required: "Date of birth is required" }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} stye={{width:"100%"}}>
                        <DatePicker
                         sx={{
                          '& .MuiTextField-root': {
                              width: '100%',
                          },
                          
                         }}
                         {...field}
                          openTo="day"
                          views={["day", "month", "year"]}
                          renderInput={(params) => <TextField {...params} size="large" inputProps={{...params.inputProps, readOnly: true}}/>}
                        />
                      </LocalizationProvider>
                      )}
                  />
                 
                      <ErrorMessage
                      errors={errors}
                      name="birthDate"
                      render={({ message }) => (
                          <Alert variant="danger" className="p-1 mb-0">
                          {message}
                          </Alert>
                      )}
                      />
                     
                  </div>
                  <div className="col-xl-12">
                    <label
                      for="signup-email"
                      className="form-label text-default op=8 me-5"
                    >
                      Gender 
                    </label>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="gender"
                           value="option1"  {...register("gender", { required: "Please select an option" })} />
                        <label class="form-check-label" >Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="gender"
                            value="option2"  {...register("gender", { required: "Please select an option" })}/>
                        <label class="form-check-label" >Female</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="gender"
                             value="option3"   {...register("gender", { required: "Please select an option" })}/>
                        <label class="form-check-label" >Other</label>
                    </div>
                      <ErrorMessage
                      errors={errors}
                      name="gender"
                      render={({ message }) => (
                          <Alert variant="danger" className="p-1 mb-0">
                          {message}
                          </Alert>
                      )}
                      />
                     
                  </div>
                  <div className="col-xl-12">
                    <label
                      for="signup-email"
                      className="form-label text-default op=8"
                    >
                      Email
                    </label>
                    <input
                      type="Email"
                      className="form-control form-control-lg"
                      id="signup-email"
                      placeholder="Email"
                      control={control} {...register("emailId", { required: "This field is required" ,pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter valid email",
                      }})} aria-invalid={errors.email ? true : false} 
                      />
                      <ErrorMessage
                      errors={errors}
                      name="emailId"
                      render={({ message }) => (
                          <Alert variant="danger" className="p-1 mb-0">
                          {message}
                          </Alert>
                      )}
                      />
                     
                  </div>
                  <div className="col-xl-12">
                    <label
                      for="signup-email"
                      className="form-label text-default op=8"
                    >
                      Mobile Number
                    </label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{ required: "Phone number is required" }}
                      render={({ field }) => (
                          <PhoneInput
                              {...field}
                              country={'in'}
                              inputStyle={{width:"100%"}}
                          />
                      )}
                  />
                      <ErrorMessage
                      errors={errors}
                      name="phoneNumber"
                      render={({ message }) => (
                          <Alert variant="danger" className="p-1 mb-0">
                          {message}
                          </Alert>
                      )}
                      />
                     
                  </div>
                  <div className="col-xl-12">
                    <label
                      for="signup-password"
                      className="form-label text-default op=8"
                    >
                      Password
                    </label>
                    <div className="input-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="signup-password"
                        placeholder="password"
                        control={control} {...register("password", { required: "This field is required" ,
                        minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters"
                        },
                          pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&]).{6,}$/,
                            message: "Password must contain atleast one uppercase, one lowercase, one number and special character.",
                          }})} aria-invalid={errors.password ? true : false} 
                        />
                        <ErrorMessage
                        errors={errors}
                        name="password"
                        render={({ message }) => (
                            <Alert variant="danger" className="p-1 mb-0">
                            {message}
                            </Alert>
                        )}
                        />
                       
                    </div>
                  </div>
                  <div className="col-xl-12 mb-2">
                    <label
                      for="signup-confirmpassword"
                      className="form-label text-default op=8"
                    >
                      Confirm Password
                    </label>
                    <div className="input-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="signup-confirmpassword"
                        placeholder="confirm password"
                        control={control} {...register("confirmpassword", { required: "This field is required",
                        validate: (value) =>
                            value === password || "The passwords do not match"
                         })} aria-invalid={errors.confirmpassword ? true : false} 
                        />
                        <ErrorMessage
                        errors={errors}
                        name="confirmpassword"
                        render={({ message }) => (
                            <Alert variant="danger" className="p-1 mb-0">
                            {message}
                            </Alert>
                        )}
                        />
                       
                    </div>
                  
                  </div>
                  <div className="col-xl-12 d-grid mt-2">
                    <button type="submit" className="btn btn-lg btn-primary" disabled={isLoading}>
                      Sign Up {isLoading && <span class="spinner-border spinner-border-sm align-middle" role="status"
                aria-hidden="true"></span>}
                    </button>
                    <div className="text-center">
                      <p className="fs-14 text-muted mt-3">Already have an Account ?

<Link to="/" className="text-primary fw-semibold"> Sign In</Link></p>
                  </div>
                  </div>
                  
                  
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></>);
};

export default UserRegister;
