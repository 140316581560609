import { Routes, Route } from "react-router-dom";
import ProfileSetting from "../../components/User/profileSetting/profileSetting";
import UserDashBoard from "../../components/User/dashBoard/UserDashBoard";
import EditUser from "../../components/User/editUser/EditUser"

const User = () => {
  return (
    <Routes>
      <Route path="/" element={<UserDashBoard />} />
      <Route  path="/edit/:id" element={<EditUser />} />
      <Route path="/profile-setting" element={<ProfileSetting />} />
    </Routes>
  );
};

export default User;