import { createContext, useReducer } from "react";
import { toast } from "react-toastify";
import { axios,axiosPrivate } from "../../api/axios";
import TokenService from "../../api/token.service";
import CommonReducer from "./CommonReducer";
import {useNavigate } from "react-router-dom";

const CommonContext = createContext({});

const intialAuthState = {
  isLoading: false,
  isError: false,
  weightList:[],
  heightList:[],
  educationList:[],
  professionsList:[],
  maritalList:{"male":["Never Married","Divorced","Windower"],"female":["Never Married","Divorced","Window"]},
  stateList:[],
  totalRow:0,
 
};


export const CommonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CommonReducer, intialAuthState);
  const navigate = useNavigate();

  const getWeightList = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.get("user/commons/weight")
      const { data } = response;
      if (data.response=="OK") {
        const date = data.responseObject;
        dispatch({ type: "GET_WEIGHT_LIST", payload: date });
      }  else {
        toast.error(data.message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
        dispatch({ type: "LOGIN_ERROR" });
      }
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  const getHeightList = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.get("user/commons/height")
      const { data } = response;
      if (data.response=="OK") {
        const date = data.responseObject;
        dispatch({ type: "GET_HEIGHT_LIST", payload: date });
      }  else {
        toast.error(data.message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
        dispatch({ type: "LOGIN_ERROR" });
      }
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  const getEducationList = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.get("user/commons/education")
      const { data } = response;
      if (data.response=="OK") {
        const date = data.responseObject;
        dispatch({ type: "GET_EDUCATION_LIST", payload: date });
      }  else {
        toast.error(data.message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
        dispatch({ type: "LOGIN_ERROR" });
      }
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  const getProfessionsList = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.get("user/commons/professions")
      const { data } = response;
      if (data.response=="OK") {
        const date = data.responseObject;
        dispatch({ type: "GET_PROFESSIONS_LIST", payload: date });
      }  else {
        toast.error(data.message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
        dispatch({ type: "LOGIN_ERROR" });
      }
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  const getStateList = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.get("user/commons/states")
      const { data } = response;
      if (data.response=="OK") {
        const date = data.responseObject;
        dispatch({ type: "GET_STATE_LIST", payload: date });
      }  else {
        toast.error(data.message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
        dispatch({ type: "LOGIN_ERROR" });
      }
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  

  return (
    <CommonContext.Provider
      value={{
        getWeightList,
        getHeightList,
        getEducationList,
        getProfessionsList,
        weightList:state.weightList,
        heightList:state.heightList,
        educationList:state.educationList,
        professionsList:state.professionsList,
        maritalList:state.maritalList,
        getStateList,
        stateList:state.stateList,
       
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;