import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons'; 
import 'react-phone-input-2/lib/style.css'
import PersonalSettingForm from "../../Settings/personalSetting/personalSettingForm"
import UserInformationForm from "../../Settings/userInformationForm/userInformationForm"
import FamilyDetailForm from "../../Settings/familyDetailForm/FamilyDetailForm"
import AddressDetailForm from "../../Settings/addressDetailForm/addressDetailForm"
import ProfileBackgroundImg from "../../../assets/img/png-images/2.png"

import { BsFillPencilFill } from "react-icons/bs";
import {
  MdLockOutline,
  MdOutlinePersonRemoveAlt1,
  MdOutlinePersonAddAlt1,
} from "react-icons/md";

import {
  Button,
  IconButton,
  Tooltip,
  FormControl,
  FormControlLabel,
  Switch,
} from "@mui/material";
import dayjs from "dayjs";
import RefreshIcon from "@mui/icons-material/Refresh";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useForm,Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import UserContext from "../../../context/userProfile-list/UserProvider";
import CommonContext from "../../../context/common-api/CommonProvider";
import BlockLoader from "../../blockloader/BlockLoader"
const initialPagination = {
  pageIndex: 0,
  pageSize: 10,
};
const EditUser = () => {
  const { getUserProfileDetails,profileDetails,updatePesrsonalDetail,updateFamilyDetail,updateUserInformation,updateAddressDetail,isLoading,isUserDetailLoading} = useContext(UserContext);
  const {getWeightList,weightList,getHeightList,heightList,getEducationList,educationList,getProfessionsList,professionsList,maritalList,getStateList,stateList} = useContext(CommonContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isImgUpload, setIsImgUpload] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeTab, setActiveTab] = useState('first');
  let { id } = useParams();
  useEffect(() => {
    getUserProfileDetails(id);
    getWeightList();
    getHeightList();
    getEducationList();
    getProfessionsList();
    getStateList();
   
  }, [id]);
  const handleSubmit = async (type, id,data) => {
    if(type==="Personal")
    {
      updatePesrsonalDetail(id,data);
    }
    else if(type==="UserInformation")
    {
      updateUserInformation(id,data);
    }
    else if(type==="Family")
    {
      updateFamilyDetail(id,data);
    }
    else{
      updateAddressDetail(id,data)
    }
    
  };
  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    const isLessThan1MB = imageFile.size <= 1000000; // 1MB in bytes

    if (isLessThan1MB) {
      setSelectedImage(imageFile);
      setErrorMessage(null); // Clear previous error if any

      // Preview image (optional)
      if (imageFile) {
        const reader = new FileReader();
        reader.onload = (e) => setImageUrl(e.target.result);
        reader.readAsDataURL(imageFile);
        setIsImgUpload(true)
      }
    } else {
      setIsImgUpload(false)
      setSelectedImage(null); // Clear selected image if too large
      setErrorMessage('Image size cannot exceed 1MB. Please select a smaller image.');
    }
  };
  const handleImageUpload = async (event) => {
    event.preventDefault();

    if (!selectedImage) {
      setErrorMessage('Please select an image to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedImage);

    try {
      const response = await fetch('/upload-image', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      setImageUrl(data.imageUrl); // Assuming your server returns the image URL
      console.log('Image uploaded successfully!', response);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setSelectedImage(null); // Clear selected image for next upload
    }
  };
  const handleClearImg = () => {
   
      setIsImgUpload(false)
      setSelectedImage(null); 
      setImageUrl(null)
     
   
  };
  const handleSelect = (key) => {
    setActiveTab(key);
   
  };
  return (
    <>
    {isUserDetailLoading && <BlockLoader position={"fixed"} />}
      <div className="main-content app-content">
        <div className="container-fluid">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
            <h1 className="page-title fw-medium fs-24 mb-0">
              Profile Settings
            </h1>
            <div className="ms-md-1 ms-0">
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0);">Pages</a>
                  </li>
                  <li
                    className="breadcrumb-item active d-inline-flex"
                    aria-current="page"
                  >
                    profile-settings
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Header Close */}
          {/* Start::row-1 */}
          {Object.keys(profileDetails).length>0 &&
          <div className="row">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first" onSelect={handleSelect}>
              <div className="col-xxl-3 col-lg-4">
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="profile-container mb-4">
                      <div className="profile-setting-cover">
                        <img
                          src={ProfileBackgroundImg}
                          alt=""
                          className=""
                          id="profile-img2"
                        />
                        <div className="main-profile-cover-content">
                          <div className="text-center">
                            <span className="avatar avatar-rounded chatstatusperson">
                            {imageUrl && <img src={imageUrl} alt="Uploaded Image" />}
                              <span className="profile-upload  cursor-pointer">
                              <FontAwesomeIcon icon={faPen} className="" style={{"padding": "5px"}}/>
                                <input
                                  type="file"
                                  className="absolute inset-0 w-full h-full opacity-0 "
                                  accept="image/*"
                                  onChange={handleImageChange}
                                  id="profile-change"
                                />
                              </span>
                            </span>
                            
                            {errorMessage && <p className="text-danger">{errorMessage}</p>}
                          </div>
                          {isImgUpload &&
                            <div className="mt-3">
                              <button type="button" onClick={handleImageUpload} class="btn btn-success-light btn-wave me-2">Upload</button>
                              <button type="button" class="btn btn-danger-light btn-wave" onClick={handleClearImg}>Cancel</button>
                            </div>
                          }
                          
                          {/* <span className="background-upload">
                            <i className="ri ri-pencil-line" />
                            <input
                              type="file"
                              name="photo"
                              accept="image/*"
                              
                            />
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link  bg-light border"
                          eventKey="first"
                        >
                          {" "}
                          Personal Settings
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link  bg-light border mt-3"
                          eventKey="second"
                        >
                          User Information
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link  bg-light border mt-3"
                          eventKey="three"
                        >
                          Family Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link  bg-light border mt-3"
                          eventKey="four"
                        >
                          Address Details
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </div>
              <div className="col-xxl-9 col-lg-8">
                <div className="card custom-card border-0">
                  <div className="card-body p-0">
                    <Tab.Content>
                      <Tab.Pane eventKey="first" className="border-0 p-0">
                        <div className="card custom-card mb-0">
                          <div className="card-header">
                            <div className="card-title d-flex">
                              <i className="ri ri-shield-user-line me-2" />
                              Personal Settings
                            </div>
                          </div>
                          {activeTab==="first" &&
                            <PersonalSettingForm personalData={profileDetails}
                            submitFrom={handleSubmit}></PersonalSettingForm>
                          }
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="border-0 p-0">
                        <div className="card custom-card mb-0">
                            <div className="card-header">
                              <div className="card-title d-flex">
                                <i className="ri ri-shield-user-line me-2" />
                                User Information
                              </div>
                            </div>
                            {activeTab==="second" &&
                              <UserInformationForm personalData={profileDetails} weightList={weightList} weightId={weightList.filter((x) => x.weightType === profileDetails?.weight)[0]} 
                              heightList={heightList} heightId={heightList.filter((x) => x.heightType === profileDetails?.height)[0]}
                              educationList={educationList} educationId={educationList.filter((x) => x.levelName === profileDetails?.education)[0]}
                              professionsList={professionsList} professionId={professionsList.filter((x) => x.professionType === profileDetails?.occupation)[0]}
                              maritalList={maritalList} gender={profileDetails?.gender?profileDetails?.gender.toLowerCase():null}></UserInformationForm>
                            }
                           
                          </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="three" className="border-0 p-0">
                        <div className="card custom-card mb-0">
                            <div className="card-header">
                              <div className="card-title d-flex">
                                <i className="ri ri-shield-user-line me-2" />
                                Family Details
                              </div>
                            </div>
                            {activeTab==="three" &&
                              <FamilyDetailForm personalData={profileDetails} 
                              professionsList={professionsList} 
                              fatherProfId={professionsList.filter((x) => x.professionType === profileDetails?.fatherOccupation)[0]}
                              motherProfId={professionsList.filter((x) => x.professionType === profileDetails?.motherOccupation)[0]} submitFrom={handleSubmit}
                              ></FamilyDetailForm>
                            }
                           
                          </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="four" className="border-0 p-0">
                        <div className="card custom-card mb-0">
                            <div className="card-header">
                              <div className="card-title d-flex">
                                <i className="ri ri-shield-user-line me-2" />
                                Address Details
                              </div>
                            </div>
                            {activeTab==="four" &&
                              <AddressDetailForm personalData={profileDetails} stateList={stateList}
                              stateID={stateList.filter((x) => x.name == profileDetails?.state)[0]?.id}
                              ></AddressDetailForm>
                            }
                           
                          </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </div>
            </Tab.Container>
          </div>}
          {/*End::row-1 */}
        </div>
      </div>
    </>
  );
};
export default EditUser;
