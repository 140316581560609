import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {AuthProvider} from './context/user-auth/AuthProvider';
import {UserProvider} from "./context/userProfile-list/UserProvider";
import {CommonProvider} from "./context/common-api/CommonProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <CommonProvider>
          <App />
          </CommonProvider>
        </UserProvider>
      
      </AuthProvider>
    </BrowserRouter>
   
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
