import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "react-bootstrap";

import {
  Button,
  Typography,
  TextField,
  Box,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  InputLabel,
  InputAdornment,
  Autocomplete
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import "dayjs/locale/en-gb";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { format } from "date-fns";
import { useParams, useLocation } from "react-router-dom";

const UserInformationForm = ({
  personalData,
  weightList,
  weightId,
  heightList,
  heightId,
  educationList,
  educationId,
  professionsList,
  professionId,
  maritalList,
  gender
}) => {
  const location = useLocation();
  const [value, setValue] = useState(educationId);
  const [profID, setProfID] = useState(professionId);
  const [heightID, setHeightID] = useState(heightId);
  const [weightID, setWeightID] = useState(weightId);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      brithTime: personalData?.birthTIme ? dayjs(personalData?.birthTIme, "h:mm a"): null,
      edu: educationId,
      prof: professionId,
      phyChal:"no",
      monthEarn: personalData?.earningPerMonth
        ? personalData?.earningPerMonth
        : 0,
      height: heightId,
      weight: weightId,
      mstatus: personalData?.maritalStatus ? personalData?.mstatus : null,
      aboutMe: personalData?.aboutMe ? personalData?.aboutMe : "",
    },
  });
  useEffect(() => {
    return () => {
      reset();
    };
  }, [location, reset]);
  const onSubmit = (data) => {
    console.log(data);
    let inputData={
      "birthTime": dayjs(data.brithTime).format('h:mm A'),
      "education": data.edu,
      "profession": data.prof,
      "earningPerMonth": data.monthEarn,
      "aboutMe":data.aboutMe,
      "height": data.height,
      "weight": data.weight,
      "maritalStatus": data.mstatus,
      "physicallyChallenged": data.phyChal
    }
    //submitFrom("Family",personalData?.userId,inputData)
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row gy-3">
            <div className="col-md-12">
              <Controller
                name="brithTime"
                control={control}
                //defaultValue={personalData?.birthTIme ? dayjs(personalData?.birthTIme, "h:mm a").toDate() : null}
                rules={{ required: "Birth Time is required" }}
                render={({ field }) => (
                  <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  stye={{ width: "100%" }}
                >
                    <MobileTimePicker
                      {...field}
                      label="Preferred Time"
                      // defaultValue={
                      //   personalData?.birthTIme ? dayjs(personalData?.birthTIme, "h:mm a").toDate() : null
                      // }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // sx={{
                          //   width: 700,
                          //   "& .MuiInputBase-root": {
                          //     backgroundColor: "#f0f0f0",
                          //   },
                          //   "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          //     borderColor: "#3f51b5",
                          //   },
                          //   "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                          //     borderColor: "#f44336",
                          //   },
                          // }}
                          size="small"
                          // error={!!errors.brithTime}
                          // helperText={
                          //   errors.brithTime ? errors.brithTime.message : ""
                          // }
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              
            </div>
            <div className="col-md-6">
              {/* <Controller
                name="edu"
                control={control}
                rules={{ required: "Education is required" }}
              
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                      size="small"
                      label="Select your education"
                      {...field}
                      error={!!errors.edu}
                      helperText={errors.edu ? errors.edu.message : ""}
                    >
                      {educationList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.levelName}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              /> */}
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="select-field">Select your education</InputLabel>
                <Select
                  {...register('edu', {
                    required: 'Selection is required',
                  })}
                   label="Select your education"
                  labelId="select-field"
                  error={!!errors.edu}
                  helperText={errors.edu ? errors.edu.message : ""}
                  defaultValue={educationId}
                >
                  {educationList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.levelName}</MenuItem>
                      ))}
                </Select>
              </FormControl> */}
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel id="select-your-education">Select your education</InputLabel>
                <Controller
                  name="edu"
                  control={control}
                  defaultValue={educationId}
                  render={({ field }) => (
                    <Select labelId="select-your-education" label="Select your education" {...field}>
                       {educationList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.levelName}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl> */}
              <Autocomplete
               name="edu"
                options={educationList}
                getOptionLabel={(option) => option.levelName}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                value={value}
                renderInput={(params) => (
                  <TextField {...params} label="Select your education" variant="outlined" />
                )}
              />
            </div>
            <div className="col-md-6">
              {/* <Controller
                name="prof"
                control={control}
                rules={{ required: "Profession is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                      size="small"
                      label="Select your profession"
                      {...field}
                      error={!!errors.prof}
                      helperText={errors.prof ? errors.prof.message : ""}
                    >
                      {professionsList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.professionType}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              /> */}
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="select-field">Select your profession</InputLabel>
                <Controller
                  name="prof"
                  control={control}
                  render={({ field }) => (
                    <Select labelId="select-your-profession" 
                    label="Select your profession" 
                    error={!!errors.prof}
                  helperText={errors.prof ? errors.prof.message : ""}
                    {...field}>
                       {professionsList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.professionType}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {/* <Select
                  {...register('prof', {
                    required: 'Selection is required',
                  })}
                   label="Select your profession"
                  labelId="select-field"
                  error={!!errors.prof}
                  helperText={errors.prof ? errors.prof.message : ""}
                >
                  {professionsList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.professionType}</MenuItem>
                      ))}
                </Select> 
              </FormControl> */}

              <Autocomplete
               name="prof"
                options={professionsList}
                getOptionLabel={(option) => option.professionType}
                onChange={(event, newValue) => {
                  setProfID(newValue);
                }}
                value={profID}
                renderInput={(params) => (
                  <TextField {...params} label="Select your profession" variant="outlined" />
                )}
              />
            </div>

            <div className="col-md-12">
              <Controller
                name="phyChal"
                size="small"
                control={control}
                rules={{ required: "Physically is required" }}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    component="fieldset"
                    margin="normal"
                    error={!!errors.phyChal}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <FormLabel
                          style={{ marginTop: "8px" }}
                          component="legend"
                          className="d-inline"
                        >
                          Physically Challenged
                        </FormLabel>
                      </div>
                      <div className="col-md-8">
                        <RadioGroup style={{ width: "auto" }} row {...field}>
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    {errors.phyChal && (
                      <Typography color="error">
                        {errors.phyChal.message}
                      </Typography>
                    )}
                  </FormControl>
                )}
              />
            </div>

            <div className="col-md-12">
              <Controller
                name="monthEarn"
                control={control}
                defaultValue={personalData?.earningPerMonth}
                rules={{
                  required: "Monthly Earnings is required",
                  validate: {
                    positive: (value) =>
                      parseFloat(value) > 0 || "Enter vaild amount",
                    decimalPlaces: (value) =>
                      /^\d+(\.\d{1,2})?$/.test(value) ||
                      "Amount can have up to 2 decimal places",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Monthly Earnings"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">&#8377;</InputAdornment>
                      ),
                    }}
                    error={!!errors.monthEarn}
                    helperText={
                      errors.monthEarn ? errors.monthEarn.message : ""
                    }
                  />
                )}
              />
            </div>

            <div className="col-md-6">
              {/* <Controller
                name="height"
                control={control}
                rules={{ required: "Height is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                      size="small"
                      label="Select your height"
                      {...field}
                      error={!!errors.height}
                      helperText={errors.height ? errors.edu.message : ""}
                    >
                      {heightList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.heightType}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              /> */}
               {/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="select-your-height">Select your height</InputLabel>
                <Controller
                  name="height"
                  control={control}
                  render={({ field }) => (
                    <Select labelId="select-your-height" 
                    label="Select your height" 
                    error={!!errors.height}
                  helperText={errors.height ? errors.height.message : ""}
                    {...field}>
                       {heightList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.heightType}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl> */}
              <Autocomplete
               name="height"
                options={heightList}
                getOptionLabel={(option) => option.heightType}
                onChange={(event, newValue) => {
                  setHeightID(newValue);
                }}
                value={heightID}
                renderInput={(params) => (
                  <TextField {...params} label="Select your height" variant="outlined" />
                )}
              />
            </div>
            <div className="col-md-6">
              {/* <Controller
                name="weight"
                control={control}
                rules={{ required: "Weight is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                      size="small"
                      label="Select your weight"
                      {...field}
                      error={!!errors.weight}
                      helperText={errors.weight ? errors.weight.message : ""}
                    >
                      {weightList?.map((val, index) => (
                        <MenuItem value={val.id}>{val.weightType}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              /> */}
              <Autocomplete
               name="weight"
                options={weightList}
                getOptionLabel={(option) => option.weightType}
                onChange={(event, newValue) => {
                  setWeightID(newValue);
                }}
                value={weightID}
                renderInput={(params) => (
                  <TextField {...params} label="Weight is required" variant="outlined" />
                )}
              />
            </div>
            <div className="col-md-12">
              <Controller
                name="mstatus"
                control={control}
                defaultValue={personalData?.maritalStatus}
                rules={{ required: "Marital is required" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      select
                      size="small"
                      label="Select your Marital status"
                      {...field}
                      error={!!errors.mstatus}
                      helperText={errors.mstatus ? errors.mstatus.message : ""}
                    >
                      {maritalList[gender]?.map((val, index) => (
                        <MenuItem value={val}>{val}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              />
            </div>
            <div className="col-md-12">
            <Controller
              name="aboutMe"
              control={control}
              render={({ field }) => (
                  <TextField
                    label="About me"
                    {...field}
                    
                  rows={4}
                    error={!!errors.aboutMe}
                    helperText={errors.aboutMe ? errors.aboutMe.message : ''}
                  >
                   
                  </TextField>
              )}
            />
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="text-end d-flex justify-content-end">
            <button className="btn btn-primary-light me-2">
              <i className="ri ri-refresh-line me-1" /> Update{" "}
            </button>
            <button className="btn btn-secondary-light">
              <i className="ri ri-close-circle-line me-1" />
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserInformationForm;
