
import React, { useEffect} from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import './App.css';
import UserRegister from "./components/UserRegister/UserRegister"
import Login from "./components/Login/Login"
import StepperModal from "./components/Modals/StepperModal/stepperModal"
import Header from "./pages/header/Header"
import DashBoard from "./pages/dashBoard/dashBoard"
import User from "./pages/user/user"
import Landing from "./pages/Landing/landing"
import Sidebar from "./pages/Landing/Sidebar"
import RequireAuth from "./utils/RequireAuth";
import PageNotFound from "./pages/pageNotFound/PageNotFound"
import TermUse from "./pages/termUse/termUse"
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy"

function App() {

  useEffect(() => {
    // Function to check window width and update isMobile state
    const htmlTag = document.getElementsByTagName("html")[0];
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        
        htmlTag.setAttribute("data-toggled", "close");
        
      } else {
        
        htmlTag.setAttribute("data-toggled", "");
       
      }
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
    <Routes>
        <Route path="login"  element={<Login />} />
        <Route path="/"  element={<Landing />} />
        <Route path="register" element={<UserRegister />} />
        <Route path="term-use" element={<TermUse />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
       
        <Route element={<RequireAuth />}>
          
          <Route path="/admin" element={<Header />}>
              <Route index element={<DashBoard />} />
              <Route path="user/*" element={<User />} />
              <Route  path="stepper"element={<StepperModal />} />
          </Route>
         
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
    

  );
}

export default App;
