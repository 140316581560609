import { useLocation, Navigate, Outlet } from "react-router-dom";
import TokenService from "../api/token.service";

const RequireAuth = ({ allowedRoles }) => {
  const userDetail = TokenService.getUser();
  console.log(userDetail);
  const location = useLocation();
  return userDetail.userId==18 ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
