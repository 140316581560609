import React, { useContext, useState ,useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser} from "@fortawesome/free-solid-svg-icons";
import { faBars} from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../context/user-auth/AuthProvider"
import { useLocation } from 'react-router-dom';
import LOGO from "../../assets/img/bm-img/bm-logo.jpg"
const SideMenu = () => {
  const {userDetails,getLogOut} = useContext(AuthContext);
  const location = useLocation();
    const [isSideMenuOpen,setIsSideMenuOpen] = useState(true);
    const [menuOpenFlag,setMenuOpenFlag] = useState({first:false});
    const [isTopNavUserOpen,setTopNavUserOpen] = useState(false);
    const [urlName,setUrlName] = useState(false);
    useEffect(() => {
      setUrlName(location.pathname);
      }, []);
      const htmlTag = document.getElementsByTagName("html")[0];
      const onHandleSideMenu = (isSideMenuOpen) => {
        
        if(!isSideMenuOpen)
        {
          htmlTag.setAttribute("data-toggled", "close");
          
        }
        else{
          
          htmlTag.setAttribute("data-toggled", "open");
          
        }
        setIsSideMenuOpen(!isSideMenuOpen)
      };
    const menuHandle = (stage) => {
        setMenuOpenFlag(stage);
      };
      const onHandleCloseSideMenu = () => {
        htmlTag.setAttribute("data-toggled", "close");
      };
      const topNavUserHandle = (data) => {
        setTopNavUserOpen(data);
      };
      const logOutHandle = () => {
        getLogOut();
      };
     
    return(
        <>
        <header className="app-header">
          {/* Start::main-header-container */}
          <div className="main-header-container container-fluid">
            {/* Start::header-content-left */}
            <div className="header-content-left">
              {/* Start::header-element */}
              <div className="header-element">
                <div className="horizontal-logo">
                  <a href="/" className="header-logo">
                    <img
                      src={LOGO}
                      alt="logo"
                      className="desktop-logo"
                    />
                    <img
                    src={LOGO}
                      alt="logo"
                      className="toggle-logo"
                    />
                    <img
                      src={LOGO}
                      alt="logo"
                      className="desktop-dark"
                    />
                    <img
                     src={LOGO}
                      alt="logo"
                      className="toggle-dark"
                    />
                    <img
                      src={LOGO}
                      alt="logo"
                      className="desktop-white"
                    />
                    <img
                      src={LOGO}
                      alt="logo"
                      className="toggle-white"
                    />
                  </a>
                </div>
              </div>
              {/* End::header-element */}
              {/* Start::header-element */}
              <div className="header-element">
                {/* Start::header-link */}
                <div className="">
                  <a
                    className="sidebar-toggle sidemenu-toggle header-link"
                    data-bs-toggle="sidebar"
                    href="javascript:void(0);"
                    onClick={onHandleSideMenu}
                  >
                    <span className="sr-only">Toggle Navigation</span>
                    <span className="ri-arrow-right-circle-line header-icon"><FontAwesomeIcon icon={faBars} /></span>
                  </a>
                </div>
                {/* <a aria-label="Hide Sidebar" class="sidemenu-toggle header-link animated-arrow hor-toggle horizontal-navtoggle" data-bs-toggle="sidebar" href="javascript:void(0);"><span></span></a> */}
                {/* End::header-link */}
              </div>
              {/* End::header-element */}
            </div>
            {/* End::header-content-left */}
            {/* Start::header-content-right */}
            <div className="header-content-right">
              
              {/* End::header-element */}
              {/* Start::header-element */}
              <div className="header-element">
                {/* Start::header-link|dropdown-toggle */}
                <a
                  href="javascript:void(0);"
                  className="header-link dropdown-toggle"
                  id="mainHeaderProfile"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                  onClick={() =>topNavUserHandle(!isTopNavUserOpen)}>
                
                  <div className="d-flex align-items-center">
                    <div className="fs-5">
                      <FontAwesomeIcon icon={faUser} /> 
                    </div>
                  </div>
                </a>
                {/* End::header-link|dropdown-toggle */}
                <div
                className={`main-header-dropdown dropdown-menu pt-0 overflow-hidden header-profile-dropdown dropdown-menu-end ${isTopNavUserOpen? 'show' : ''}`}
                  aria-labelledby="mainHeaderProfile"
                >
                  <div className="header-dropdown bg-primary text-fixed-white rounded-top">
                    <div className="d-flex align-items-center">
                      <div className="me-sm-2 me-0 avatar fs-5">
                       
                        <FontAwesomeIcon icon={faUser} /> 
                    
                      </div>
                      <div className="d-sm-block d-none">
                        <p className="fw-semibold mb-0 lh-1">{userDetails.firstName} {userDetails.lastName}</p>
                        <span className="op-7 fw-medium d-block fs-12">
                          Admin
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-divider mb-0" />
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a className="dropdown-item d-flex d-none" href="profile.html">
                        <i className="ti ti-user-circle fs-18 me-2" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item d-flex" href="#" onClick={logOutHandle}>
                        <i className="ti ti-logout fs-18 me-2" />
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End::header-element */}
              
            </div>
            {/* End::header-content-right */}
          </div>
          {/* End::main-header-container */}
        </header>
        {/* End Main-Header */}
        {/*Main-Sidebar*/}
        <aside className="app-sidebar" id="sidebar">
          {/* Start::main-sidebar-header */}
          <div className="main-sidebar-header ">
            <a href="/" className="header-logo">
              <img
                 src={LOGO}
                alt="logo"
                className="main-logo desktop-logo"
              />
              <img
                 src={LOGO}
                alt="logo"
                className="main-logo toggle-logo"
              />
              <img
                 src={LOGO}
                alt="logo"
                className="main-logo desktop-dark"
              />
              <img
                 src={LOGO}
                alt="logo"
                className="main-logo toggle-dark"
              />
              <img
                 src={LOGO}
                alt="logo"
                className="desktop-white"
              />
              <img
                 src={LOGO}
                alt="logo"
                className="toggle-white"
              />
            </a>
          </div>
          {/* End::main-sidebar-header */}
          {/* Start::main-sidebar */}
          <div className="main-sidebar " id="sidebar-scroll">
            {/* Start::nav */}
            <div className="text-end  d-md-none">
              <span className="ri-arrow-right-circle-line header-icon me-2 " onClick={onHandleCloseSideMenu}>
                <FontAwesomeIcon icon={faCircleXmark} />
              </span>
            </div>
             
           
            <nav className="main-menu-container nav nav-pills flex-column sub-open">
              <div className="slide-left" id="slide-left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#7b8191"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                >
                  <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                </svg>
              </div>
              <ul className="main-menu">
                {/* <li className={`slide  has-sub ${menuOpenFlag.first ? 'open' : ''}`}>
                  <a href="javascript:void(0);" className="side-menu__item" onClick={() =>menuHandle({first:!menuOpenFlag.first})}>
                    <i className="ri-home-8-line side-menu__icon" />
                    <span className="side-menu__label">Dashboards</span>
                    <FontAwesomeIcon icon={faChevronRight} className="side-menu__angle" />
                  </a>
                  <ul className={`slide-menu child1 ${menuOpenFlag.first ? 'd-block' : 'd-none'}`}>
                    <li className="slide">
                      <a href="/" className="side-menu__item">
                        Sales
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li class="slide">
                  <a href="/admin" className={`side-menu__item ${urlName=="/" ? 'active' : ''}`}>
                    <i className="ri-apps-2-line side-menu__icon"></i>
                    <span className="side-menu__label">Dashborad</span>
                  </a>
                </li>
                <li className="slide">
                  <a href="/admin/user" className={`side-menu__item ${urlName=="/user" ? 'active' : ''}`}>
                    <i className="ri-apps-2-line side-menu__icon"></i>
                    <span className="side-menu__label">User</span>
                  </a>
                </li>
              </ul>
            </nav>
            {/* End::nav */}
          </div>
          {/* End::main-sidebar */}
        </aside>
        {/* End Main-Sidebar*/}
      </>
      
);
}
export default SideMenu;