import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "react-bootstrap";

import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { format } from "date-fns";
import { useParams, useLocation } from "react-router-dom";
import UserContext from "../../../context/userProfile-list/UserProvider";

const PersonalSettingForm = ({ personalData,submitFrom }) => {
  //const { getUserProfileDetails,profileDetails} = useContext(UserContext);
  // let { id } = useParams();
  const location = useLocation();
  // useEffect(() => {
  //   getUserProfileDetails(id);
  // }, [id]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      firstName: personalData?.firstName ? personalData?.firstName : "",
      lastName: personalData?.lastName ? personalData?.lastName : "",
      birthDate: personalData?.dob ? dayjs(personalData?.dob) : null,
      gender:  personalData?.gender ? personalData?.gender : null,
      emailId: personalData?.emailId
        ? personalData?.emailId
        : "abc@gmail.com",
      phoneNumber: personalData?.homePhone ? "91" +personalData?.homePhone : null,
    },
  });
  useEffect(() => {
    return () => {
      reset();
    };
  }, [location, reset]);
  const genderWatch = watch('gender');
  const onSubmit = (data) => {
    console.log(data);
    let inputData={
      "firstName": data.firstName,
      "lastName": data.lastName,
      "birthDate":dayjs(data.birthDate).format('YYYY-MM-DD'),
      "gender": data.gender,
      "email": data.emailId,
      "phoneNumber": data.phoneNumber.replace("91", ""),
      
    }
    submitFrom("Personal",personalData?.userId,inputData)
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body">
        <div className="row gy-3">
          <div className="col-xl-12">
            <label
              for="signup-firstname"
              className="form-label text-default op=8"
            >
              First Name
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="signup-firstname"
              placeholder="first name"
              defaultValue={
                personalData?.firstName ? personalData?.firstName : ""
              }
              control={control}
              {...register("firstName", { required: "This field is required" })}
              aria-invalid={errors.firstName ? true : false}
            />
            <ErrorMessage
              errors={errors}
              name="firstName"
              render={({ message }) => (
                <Alert variant="danger" className="p-1 mb-0">
                  {message}
                </Alert>
              )}
            />
          </div>
          <div className="col-xl-12">
            <label
              for="signup-lastname"
              className="form-label text-default op=8"
            >
              Last Name
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="signup-lastname"
              placeholder="last name"
              control={control}
              {...register("lastName")}
              defaultValue={
                personalData?.lastName ? personalData?.lastName : ""
              }
            />
          </div>
          <div className="col-xl-12">
            <label
              for="signup-email"
              className="form-label text-default op=8 me-5"
            >
              Gender
            </label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                value="male"
                defaultChecked={
                  personalData?.gender ? personalData?.gender==="Male" : false
                }
                {...register("gender", { required: "Please select an option" })}
              />
              <label class="form-check-label">Male</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                value="female"
                defaultChecked={
                  personalData?.gender ? personalData?.gender==="Female" : false
                }
                {...register("gender", { required: "Please select an option" })}
              />
              <label class="form-check-label">Female</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                value="other"
                defaultChecked={
                  personalData?.gender ? personalData?.gender==="other" : false
                }
                {...register("gender", { required: "Please select an option" })}
              />
              <label class="form-check-label">Other</label>
            </div>
            <ErrorMessage
              errors={errors}
              name="gender"
              render={({ message }) => (
                <Alert variant="danger" className="p-1 mb-0">
                  {message}
                </Alert>
              )}
            />
          </div>
          <div className="col-xl-12">
            <label className="form-label text-default op=8 d-block">
              Date of Birth {genderWatch}
            </label>
            <Controller
              name="birthDate"
              control={control}
              rules={{ required: "Date of birth is required" }}
              defaultValue={personalData?.dob ? dayjs(personalData?.dob) : null}
              render={({ field }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                  stye={{ width: "100%" }}
                >
                  <DatePicker
                    {...field}
                    format="DD-MM-YYYY"
                    maxDate={genderWatch=="male"?dayjs().subtract(18, 'year'):dayjs().subtract(20, 'year')}
                    renderInput={(params) => (
                      <TextField {...params} size="large" />
                    )}
                  />
                </LocalizationProvider>
              )}
            />

            <ErrorMessage
              errors={errors}
              name="birthDate"
              render={({ message }) => (
                <Alert variant="danger" className="p-1 mb-0">
                  {message}
                </Alert>
              )}
            />
          </div>
         
          <div className="col-xl-12">
            <label for="signup-email" className="form-label text-default op=8">
              Email
            </label>
            <input
              type="Email"
              className="form-control form-control-lg"
              id="signup-email"
              placeholder="Email"
              defaultValue={
                personalData?.email ? personalData?.email : "aphukkeri@gg.com"
              }
              control={control}
              {...register("emailId", {
                required: "This field is required",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter valid email",
                },
              })}
              aria-invalid={errors.email ? true : false}
            />
            <ErrorMessage
              errors={errors}
              name="emailId"
              render={({ message }) => (
                <Alert variant="danger" className="p-1 mb-0">
                  {message}
                </Alert>
              )}
            />
          </div>
          <div className="col-xl-12">
            <label for="signup-email" className="form-label text-default op=8">
              Mobile Number
            </label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: "Phone number is required" }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  value={
                    personalData?.homePhone
                      ? "91" + personalData?.homePhone
                      : ""
                  }
                  disableDropdown={true}
                  onlyCountries={["in"]}
                  countryCodeEditable={false}
                  inputStyle={{ width: "100%" }}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="phoneNumber"
              render={({ message }) => (
                <Alert variant="danger" className="p-1 mb-0">
                  {message}
                </Alert>
              )}
            />
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="text-end d-flex justify-content-end">
          <button className="btn btn-primary-light me-2">
            <i className="ri ri-refresh-line me-1" /> Update{" "}
          </button>
          <button className="btn btn-secondary-light">
            <i className="ri ri-close-circle-line me-1" />
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default PersonalSettingForm;
