import { createContext, useReducer } from "react";
import { toast } from "react-toastify";
import { axios,axiosPrivate } from "../../api/axios";
import TokenService from "../../api/token.service";
import UserReducer from "./UserReducer";
import {useNavigate } from "react-router-dom";

const UserContext = createContext({});

const intialAuthState = {
  isLoading: false,
  isUserDetailLoading: false,
  isError: false,
  userDetails: JSON.parse(localStorage.getItem("userDetail")) || {},
  profileList:[],
  totalRow:0,
  profileDetails:{}
 
};


export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, intialAuthState);
  const navigate = useNavigate();

  const UserProfileAPI = async (params) => {
    dispatch({ type: "LOGIN_START" });
    try {
  const response = await axiosPrivate.get(`/profiles/?page=${params.pageIndex}&size=${params.pageSize}`)
      const { data } = response;
      if (data.response=="OK") {
        const date = data.responseObject;
        dispatch({ type: "GET_USER_PROFILE_LIST", payload: date });
      }  else {
        toast.error(data.message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
        dispatch({ type: "LOGIN_ERROR" });
      }
      return data;
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
    }
  };
  const getUserProfileDetails = async (id,type) => {
    dispatch({ type: "GET_LOGGED_USER_DETAILS_START" });
    try {
      const response = await axiosPrivate.get(`/profiles/details/${id}`);
      const { data } = response;
      if(data.response=="OK")
        {
         
          dispatch({ type: "GET_USER_PROFILE_DETAILS_SUCCESS", payload: data.responseObject });
          if(type=="DashBoard")
          {
            navigate(`admin/user/edit/${id}`);
          }
          toast.success(data.message, { position: "bottom-right", autoClose: 1000, theme: "colored" });
          
        }
     
    } catch (error) {
      console.log(error);
      if(error.response.status==400)
      {
        navigate("/page-not-found");
      }
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
      dispatch({ type: "GET_LOGGED_USER_DETAILS_FAILED" });
    }
  };
  const updatePesrsonalDetail = async (id,param) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.put(`/user/updateuserprofile/${id}`,{...param});
      const { data } = response;
      if(data.response=="OK")
        {
          getUserProfileDetails(id);
          toast.success(data.message, { position: "bottom-right", autoClose: 1000, theme: "colored" });
          
        }
     
    } catch (error) {
     
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
      dispatch({ type: "GET_LOGGED_USER_DETAILS_FAILED" });
    }
  };
  const updateUserInformation = async (id,param) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.put(`/user/updateuserinfo/${id}`,{...param});
      const { data } = response;
      if(data.response=="OK")
        {
          getUserProfileDetails(id);
          toast.success(data.message, { position: "bottom-right", autoClose: 1000, theme: "colored" });
          
        }
     
    } catch (error) {
     
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
      dispatch({ type: "GET_LOGGED_USER_DETAILS_FAILED" });
    }
  };
  const updateFamilyDetail = async (id,param) => {
    dispatch({ type: "LOGIN_START" });
    try {
    
      const response = await axiosPrivate.put(`/user/family/update/${id}`,{...param});
      const { data } = response;
      
      if(data.response=="OK")
        {
          console.log(data.response);
          getUserProfileDetails(id);
          toast.success(data.message, { position: "bottom-right", autoClose: 1000, theme: "colored" });
          
        }
     
    } catch (error) {
     
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
      dispatch({ type: "GET_LOGGED_USER_DETAILS_FAILED" });
    }
  };
  const updateAddressDetail = async (id,param) => {
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axiosPrivate.put(`/user/address/${id}`,{...param});
      const { data } = response;
      if(data.response=="OK")
        {
          console.log(data.response);
          getUserProfileDetails(id);
          toast.success(data.message, { position: "bottom-right", autoClose: 1000, theme: "colored" });
          
        }
     
    } catch (error) {
     
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(message, { position: "bottom-right", autoClose: 2000, theme: "colored" });
      dispatch({ type: "GET_LOGGED_USER_DETAILS_FAILED" });
    }
  };
  
  

  return (
    <UserContext.Provider
      value={{
        UserProfileAPI,
        getUserProfileDetails,
        updateFamilyDetail,
        updatePesrsonalDetail,
        updateUserInformation,
        updateAddressDetail,
        isLoading: state.isLoading,
        isUserDetailLoading:state.isUserDetailLoading,
        auth: state.auth,
        isError: state.isError,
        profileList:state.profileList,
        totalRow:state.totalRow,
        dispatch,
        profileDetails:state.profileDetails
       
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;